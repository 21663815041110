import React from 'react';
import './home.css';
import Navbar from '../components/Navbar';
import NeuButton from '../components/NeuButton';

function App() {
  return (
    <div className="App" id="body-pd">
      <Navbar />
      <h1>Cooliewale</h1>
      <NeuButton/>
    </div>
  );
}

export default App;
