
import React, { useEffect, useState } from 'react';
import feather from 'feather-icons';
import * as Tone from 'tone';
import '../assets/css/NeumorphismButtons.css';

const NeumorphismButtons = () => {
  useEffect(() => {
    feather.replace();
  }, []);

  const handleButtonClick = (e) => {
    const button = e.currentTarget;
    const isActive = button.classList.contains('c-neu-button--active');
    button.classList.toggle('c-neu-button--active');
    window.navigator.vibrate(200);

    const synth = new Tone.Synth({
      oscillator: {
        type: 'sine',
        modulationFrequency: 0.2,
      },
      envelope: {
        attack: 0,
        decay: 0.05,
        sustain: 0,
        release: 0.2,
      },
    }).toMaster();

    synth.triggerAttackRelease(isActive ? 'G4' : 'C5', '4n');
  };

  return (
    <div className="button-container">
      <button className="c-neu-button c-neu-button--rounded" onClick={handleButtonClick}>
        <i className="c-neu-button__icon" data-feather="bell"></i>
      </button>
      <button className="c-neu-button" onClick={handleButtonClick}>
        <i className="c-neu-button__icon" data-feather="check"></i>
      </button>
      <button className="c-neu-button c-neu-button--rounded" onClick={handleButtonClick}>
        <i className="c-neu-button__icon" data-feather="power"></i>
      </button>
    </div>
  );
};

export default NeumorphismButtons;
